import jwt_decode from "jwt-decode";
import dayjs from 'dayjs'
import axios from 'axios'
import { useStore } from '../App'
import IP from './variables';



const getToken = () => {
    
    try{
        return useStore.getState()
    } catch {
        return null
    }
}


let baseURL =  `${window.location.protocol}//${IP}/api`

const useAxios = () => {
    
    const accessToken = getToken()['accessToken']
    const refreshToken = getToken()['refreshToken']

    const axiosInstance = axios.create({
        baseURL,
        headers:{Authorization: `Bearer ${accessToken}`}
    });

    axiosInstance.interceptors.request.use(async req => {
    
        const user = jwt_decode(accessToken)
        const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;
    
        if(!isExpired) return req
    
        const response = await axios.post(`${baseURL}/accounts/token/refresh`, {
            refresh: refreshToken
        });
    
        useStore.setState({ accessToken: response.data['access']})
        
        req.headers.Authorization = `Bearer ${response.data['access']}`
        return req
    })

    axiosInstance.interceptors.response.use(
        response => response,
        async error => {
            if (error.response && error.response.status === 401) {
                const response = await axios.post(`${baseURL}/accounts/token/refresh`, {
                    refresh: refreshToken
                }).catch(err => {
                    // Refresh token expired
                    return Promise.reject({ message: 'Refresh token expired', status: 401 });
                });

                useStore.setState({ accessToken: response.data['access']})
        
                error.config.headers.Authorization = `Bearer ${response.data['access']}`
                return axiosInstance(error.config);
            }
            return Promise.reject(error);
        }
    );

    return axiosInstance
}

export default useAxios;
