import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  Input,
} from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";

import { guestEditSchema } from "../../validations/validation-schemas";
import { useMutation } from "react-query";
import useAxios from "../../sections/useAxios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const EditGuestModal = ({ isOpen, toggle, data, handleResponse }) => {
  const [modalStyles, setModalStyles] = useState({
    zIndex: "1",
  });

  const onSubmit = () => {
    // const startingTime = new Date(`2000-01-01T${values.starting_time}`);
    // const endingTime = new Date(`2000-01-01T${values.ending_time}`);

    // if (endingTime < startingTime) {
    //   toast.error("Starting time cannot be after ending time");
    // } else {
    EditGuestMutation.mutate(values);
    // }
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      name: data?.name,
      company: data?.company,
      position: data?.position,
      vip: data?.vip,
    },
    validationSchema: guestEditSchema,
    onSubmit,
    enableReinitialize: true,
  });

  console.log(data);

  let api = useAxios();

  const editActivty = (payload) => {
    return api.put(`/update-guest/${data?.id}`, payload);
  };

  const EditGuestMutation = useMutation(editActivty, {
    onSuccess: (res) => {
      handleResponse("success", res);
    },
    onError: (res) => {
      handleResponse("error", res);
    },
  });

  const modules = {
    toolbar: [
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline"],
      ["clean"],
      [{ indent: "-1" }, { indent: "+1" }],
    ],
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-lg">
      <ModalHeader toggle={toggle}>
        <h4 className="text-primary mb-0">Edit Guest Details</h4>
      </ModalHeader>
      <form onSubmit={handleSubmit}>
        <ModalBody>
          <div className="row">
            <div className="col-md-6 col-12">
              <Label className="form-label text-start mt-3 mb-1">
                Name<span className="text-danger">*</span>
              </Label>
              <Input
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                className={
                  errors.name && touched.name
                    ? "form-control form-control-icon is-invalid col-6"
                    : "form-control form-control-icon green-border col-6"
                }
                id="guestName"
                placeholder="Enter guest name"
                name="name"
              />
              {errors.name && touched.name && (
                <div className="invalid-feedback">{errors.name}</div>
              )}
            </div>
            <div className="col-md-6 col-12">
              <Label className="form-label text-start mt-3 mb-1">
                Position<span className="text-danger">*</span>
              </Label>
              <Input
                value={values.position}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                className={
                  errors.position && touched.position
                    ? "form-control form-control-icon is-invalid col-6"
                    : "form-control form-control-icon green-border col-6"
                }
                id="guestPosition"
                placeholder="Enter guest position"
                name="position"
              />
              {errors.position && touched.position && (
                <div className="invalid-feedback">{errors.position}</div>
              )}
            </div>
            <div className="col-md-6 col-12">
              <Label className="form-label text-start mt-3 mb-1">
                Company<span className="text-danger">*</span>
              </Label>
              <Input
                value={values.company}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                className={
                  errors.company && touched.company
                    ? "form-control form-control-icon is-invalid col-6"
                    : "form-control form-control-icon green-border col-6"
                }
                id="companyInput"
                placeholder="Enter company's Name"
                name="company"
              />
              {errors.company && touched.company && (
                <div className="invalid-feedback">{errors.company}</div>
              )}
            </div>
            <div className="col-md-6 col-12">
              <Label className="form-label text-start mt-3 mb-1">VIP</Label>
              <Input
                value={values.vip}
                onChange={handleChange}
                onBlur={handleBlur}
                type="select"
                className={
                  errors.vip && touched.vip
                    ? "form-control form-control-icon  is-invalid col-6"
                    : "form-control form-control-icon  green-border col-6"
                }
                id="guestCompany"
                name="vip"
                style={{ border: "1px solid " }}
              >
                <option value={data?.vip ? "True" : "False"}>
                  {data?.vip ? "Yes" : "No"}
                </option>
                <option value={!data?.vip ? "True" : "False"}>
                  {!data?.vip ? "Yes" : "No"}
                </option>
              </Input>
            </div>
            {/* <div className="col-md-6 col-12">
              <Label className="form-label text-start mt-3 mb-1">
                Starting Time*
              </Label>
              <Input
                value={values.starting_time}
                onChange={handleChange}
                onBlur={handleBlur}
                type="time"
                className={
                  errors.starting_time && touched.starting_time
                    ? "form-control form-control-icon is-invalid col-6"
                    : "form-control form-control-icon green-border col-6"
                }
                id="starting_timeInput"
                name="starting_time"
              />
              {errors.starting_time && touched.starting_time && (
                <div className="invalid-feedback">{errors.starting_time}</div>
              )}
            </div>
            <div className="col-md-6 col-12">
              <Label className="form-label text-start mt-3 mb-1">
                Ending Time*
              </Label>
              <Input
                value={values.ending_time}
                onChange={handleChange}
                onBlur={handleBlur}
                type="time"
                className={
                  errors.ending_time && touched.ending_time
                    ? "form-control form-control-icon is-invalid col-6"
                    : "form-control form-control-icon green-border col-6"
                }
                id="ending_timeInput"
                name="ending_time"
              />
              {errors.ending_time && touched.ending_time && (
                <div className="invalid-feedback">{errors.ending_time}</div>
              )}
            </div> */}
          </div>
        </ModalBody>
        <ModalFooter>
          {EditGuestMutation.isLoading ? (
            <p>Processing...</p>
          ) : (
            <>
              <button
                onClick={toggle}
                type="button"
                className="btn admin-grey-btn"
              >
                Close
              </button>
              <button type="submit" className="btn admin-green-btn">
                Save
              </button>
            </>
          )}
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default EditGuestModal;
