import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { QueryClient, QueryClientProvider } from 'react-query'

import {create} from "zustand";
import { persist, devtools } from "zustand/middleware";

import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';


import 'bootstrap/dist/css/bootstrap.css';

import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';

import './asset/sass/style.scss'
import './asset/sass/utils.scss'
import './asset/sass/agenda.scss'
import './asset/sass/photo-library.scss'
import './asset/sass/admin.scss'

const queryClient = new QueryClient(
  {
    defaultOptions: {
      queries: {
        staleTime: 10* 60 * 1000, // 10 minutes
        cacheTime: 10 * 60 * 1000 // 10 minutes
      }
    }
  }
)


export const useStore = create(
  devtools(
    persist(
      (set) => ({
        userStatus: false,
        accessToken: null,
        refreshToken: null,
      }),
      {
        name: "auth-storage",
        getStorage: () => localStorage,
      }
    )
  )
);


export default function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <QueryClientProvider client={queryClient}>
          <ScrollToTop />
          <StyledChart />
          <Router />
          <ToastContainer /> {/* Add this line to render toasts */}
          </QueryClientProvider>
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
