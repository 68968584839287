import SvgColor from "../../../components/svg-color";

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const navConfig = [
  {
    title: "Guests",
    path: "/guest-listing",
    icon: icon("ic_listing"),
  },
];

export default navConfig;
