import { Navigate, useRoutes } from "react-router-dom";
import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";
import Page404 from "./pages/Page404";

import GuestListing from "./pages/admin/guest-listing";

import LoginPage from "./pages/admin/login";

import { useStore } from "./App";

const AdminRoute = ({ children }) => {
  const userStatus = useStore((state) => state.userStatus);

  return userStatus === true ? children : <Navigate to="/" />;
};

const RedirectAdmin = ({ children }) => {
  const userStatus = useStore((state) => state.userStatus);

  return userStatus === true ? (
    <Navigate to="/guest-listing" replace />
  ) : (
    children
  );
};

export default function Router() {
  const routes = useRoutes([
    {
      element: <DashboardLayout />,
      children: [
        {
          path: "guest-listing",
          element: (
            <AdminRoute>
              <GuestListing />
            </AdminRoute>
          ),
        },
      ],
    },
    {
      path: "",
      element: (
        <RedirectAdmin>
          <LoginPage />
        </RedirectAdmin>
      ),
    },
    {
      element: <SimpleLayout />,
      children: [
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
  ]);

  return routes;
}
